<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <search :search-data="searchData" />
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="dataList"
        striped
        fixed
      >
        <template #cell(company)="data">
          {{ data.item.company }}
          <div class="text-primary font-small-2">
            {{ data.item.customer_account }}
          </div>
        </template>
        <template #cell(sent)="data">
          <div v-if="data.item.sent">
            {{ moment(data.item.sent).format('llll') }}
            <div
              v-if="data.item.id_offers"
              class="text-info font-small-2"
            >
              Teklif Oluşturuldu
            </div>
            <div
              v-else
              class="text-info font-small-2"
            >
              Talep Beklemede
            </div>
          </div>
          <div
            v-else
            class="text-warning"
          >
            Talep Hazırlanıyor
          </div>
        </template>
        <template #cell(control)="data">
          <b-button
            variant="primary"
            size="sm"
            :to="'/app/offers/request/view/' + data.item.id"
          >
            Görüntüle
          </b-button>
        </template>
      </b-table>
      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCounts"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </b-card>
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BPagination, BButton, BCardFooter, BTable,
} from 'bootstrap-vue'
import Search from '@/views/Admin/Offers/Requests/elements/Search.vue'

const tableName = 'baskets'
export default {
  name: 'Index',
  components: {
    BTable,
    BRow,
    BCol,
    BCard,
    BPagination,
    BButton,
    BCardFooter,
    Search,
  },
  data() {
    return {
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      fields: [
        {
          key: 'company',
          label: 'FİRMA ADI',
        },
        {
          key: 'sent',
          label: 'TALEP TARİHİ',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-200 text-right text-nowrap',
          tdClass: 'width-200 text-right text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          'customers.company AS company',
          'customer_accounts.fullname AS customer_account',
          'users.name AS username',
          `${tableName}.sent AS sent`,
          `${tableName}.id_offers AS id_offers`,
        ],
        order_by: ['baskets.id', 'DESC'],
        limit: this.$store.state.app.perPage,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['baskets/dataList']
    },
    dataCounts() {
      return this.$store.getters['baskets/dataCounts']
    },
    search() {
      return this.$store.getters['baskets/search']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    searchData() {
      if (this.search.keyword) {
        this.dataQuery.or_like = {
          'customers.company': this.search.keyword,
          'customers.phone': this.search.keyword,
        }
      } else {
        this.dataQuery.or_like = {}
      }
      this.getDataList()
    },
    getDataList() {
      this.$store.dispatch('baskets/getDataList', this.dataQuery)
    },
  },
}
</script>
